let order = function ($) {

    let $body = $('body');

    /**
     * Run answers
     */
    let onCkecked = function () {
        $('body').on('keyup', '#module .order input[type="number"]', function (e) {
            e.preventDefault();

            let $btnAnswers = $body.find('#module .order .answers a');
            let $next = $body.find('#module > section.main > .content > .next');
            let $items = $body.find('#module .order input[type="number"]');
            let $inputAnswersUser = $body.find('#module .order .answers-user');
            let tabAnswers = {};

            $btnAnswers.removeClass('disabled');
            $next.removeClass('disabled');
            $items.each(function (k) {
                if ($(this).val() === '' || $(this).val() === 'undefined') {
                    $btnAnswers.addClass('disabled');
                    $next.addClass('disabled');
                }

                tabAnswers[k] = {};
                tabAnswers[k].order = $(this).val() !== '' || $(this).val() !== 'undefined' ? $(this).val() : '';
                tabAnswers[k].itemId = k;
                tabAnswers[k].itemTitle = removeTagHtml($(this).data('title'));

                if (k === $items.length -1) {
                    $inputAnswersUser.val(JSON.stringify(tabAnswers)).trigger('change');
                }
            });
        });
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '.order .answers a', function (e) {
            e.preventDefault();
            let _this = this;

            if ($(_this).hasClass('disabled')) return false;

            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });

        });
    };

    return {
        init: function () {
            onCkecked();
            runAnswers();
        }
    };
}(jQuery);

$(document).ready(function () {
    order.init();
});